@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600&display=swap");
@import url("https://fonts.googleapis.com/css?family=DM+Serif+Text:300,400,500,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=DM+Serif+Display&family=DM+Serif+Text&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=DM+Serif+Text&display=swap");

body {
  font-size: 17px;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  font-family: DM Sans;
}
